<template>
  <div>
    <vx-card class="" no-shadow card-border>
      <div class="vx-row mb-6">
        <div class="vx-col w-full align-center my-2">
          <span>Accommodation Rule Criteria:</span>
        </div>
        <!--#region  suppliers field-->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-col w-full text-sm">
            <span>Suppliers:</span>
          </div>
          <div class="mt-4">
            <v-select
              multiple
              v-model="acomLocalForm.accommodationRuleCriteria.suppliers"
              :reduce="(x) => x.code"
              :disabled="readOnly"
              :options="priceRulesFormData.accomSupplier"
            />
          </div>
        </div>
        <!--#endregion  suppliers field-->

        <!--#region  Suppliers Code field-->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-col w-full text-sm">
            <span>Suppliers Codes:</span>
          </div>
          <div class="mt-4">
            <v-select
              multiple
              v-model="acomLocalForm.accommodationRuleCriteria.supplierCodes"
              :reduce="(x) => x.code"
              :disabled="readOnly"
              :options="priceRulesFormData.accomSupplierCode"
            />
          </div>
        </div>
        <!--#endregion  Suppliers Codes field-->
      </div>

      <div class="vx-row mb-6">
        <!--#region  Property Types field-->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-col w-full text-sm">
            <span>Property Types:</span>
          </div>
          <div class="mt-4">
            <v-select
              multiple
              v-model="acomLocalForm.accommodationRuleCriteria.propertyTypes"
              :reduce="(x) => x.code"
              :disabled="readOnly"
              :options="acomLocalHelper.enums.propertyTypes"
            />
          </div>
        </div>
        <!--#endregion  Property Types field-->

        <!--#region  Country  field-->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-col w-full text-sm">
            <span>Country:</span>
          </div>
          <div class="mt-4">
            <v-select
              multiple
              v-model="acomLocalForm.accommodationRuleCriteria.countries"
              :reduce="(x) => x.code"
              :disabled="readOnly"
              :options="priceRulesFormData.countryCode"
            />
          </div>
        </div>
        <!--#endregion  Country  field-->
      </div>
      <div class="vx-row mb-6">
        <!--#region  BoardBasis  field-->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-col w-full text-sm">
            <span>Board Basis:</span>
          </div>
          <div class="mt-4">
            <v-select
              multiple
              v-model="acomLocalForm.accommodationRuleCriteria.boardBases"
              :reduce="(x) => x.code"
              :disabled="readOnly"
              :options="acomLocalHelper.enums.boardBasis"
            />
          </div>
        </div>
        <!--#endregion  BoardBasis  field-->

      </div>

      <!--#region Rating fields-->
      <div class="vx-row mb-6">
        <div class="vx-col w-full text-sm">
          <span>Ratings:</span>
        </div>
        <template
          v-for="(rating, ratingIndex) in acomLocalForm
            .accommodationRuleCriteria.ratings"
        >
          <!-- Ratings -->
          <div class="vx-col w-full md:w-1/2" :key="ratingIndex + '1'">
            <div class="mt-8">
              <star-rating
                class="w-full"
                :rtl="$vs.rtl"
                :star-size="30"
                :increment="0.5"
                :name="'rating_' + ratingIndex"
                v-model="
                  acomLocalForm.accommodationRuleCriteria.ratings[ratingIndex]
                "
                v-validate="{ rules: { required: true} }"
                :read-only="readOnly"
              >
              </star-rating>
              <span class="text-danger text-sm" v-if="errors.has('rating_' + ratingIndex)">{{ errors.first('rating_'+ ratingIndex).replace('rating_'+ ratingIndex, "Rating") }}</span>
            </div>
          </div>
          <div class="vx-col w-full md:w-1/2" :key="ratingIndex + '2'">
            <div class="mt-2">
              <!-- <label class="text-sm">Max</label> -->
              <vs-button
                @click="removeAcomDynamicFields(ratingIndex, 'ratings')"
                size="small"
                class="mt-8"
                type="border"
                color="danger"
                :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
                :disabled="readOnly"
                >Remove</vs-button
              >
            </div>
          </div>
        </template>

        <div class="vx-col w-full my-3">
          <vs-button
            @click="addAcomDynamicFields('ratings')"
            size="small"
            type="border"
            color="success"
            :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
            :disabled="readOnly"
            >Add</vs-button
          >
        </div>
      </div>
      <!--#endregion Rating Fields-->

      <!--#region Names  field  -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full text-sm">
          <span>Property Names:</span>
        </div>
        <template
          v-for="(name, nameIndex) in acomLocalForm.accommodationRuleCriteria
            .names"
        >
          <!-- Origin -->
          <div class="vx-col w-full md:w-1/3" :key="nameIndex + '0'">
            <div class="mt-4">
              <label class="text-sm">MatchType</label>
              <v-select
                class="w-full"
                :name="'nameMatch_' + nameIndex"
                v-model="
                  acomLocalForm.accommodationRuleCriteria.names[nameIndex]
                    .matchType
                "
                :options="acomLocalHelper.enums.matchTypes"
                :reduce="(option) => option.code"
                v-validate="{ rules: { required: true} }"
                :disabled="readOnly"
              />
              <span
                class="text-danger text-sm"
                v-if="errors.has('nameMatch_' + nameIndex)"
                >{{
                  errors
                    .first("nameMatch_" + nameIndex)
                    .replace("nameMatch_" + nameIndex, "Name match type")
                }}</span
              >
            </div>
          </div>
          <!-- Value -->
          <div class="vx-col w-full md:w-1/3" :key="nameIndex + '1'">
            <div class="mt-4">
              <label class="text-sm">Value</label>
              <vs-input
                class="w-full"
                :name="'nameValue_' + nameIndex"
                v-model="
                  acomLocalForm.accommodationRuleCriteria.names[nameIndex].value
                "
                v-validate="{ rules: { required: true } }"
                :disabled="readOnly"
              />
              <span class="text-danger text-sm" v-if="errors.has('nameValue_' + nameIndex)">{{ errors.first('nameValue_'+nameIndex).replace('nameValue_'+nameIndex, "Name value") }}</span>
            </div>
          </div>
          <div class="vx-col w-full md:w-1/3" :key="nameIndex + '2'">
            <div class="mt-3">
              <!-- <label class="text-sm">Max</label> -->
              <vs-button
                @click="removeAcomDynamicFields(nameIndex, 'names')"
                size="small"
                class="mt-8"
                type="border"
                color="danger"
                :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
                :disabled="readOnly"
                >Remove</vs-button
              >
            </div>
          </div>
        </template>

        <div class="vx-col w-full my-3">
          <vs-button
            @click="addAcomDynamicFields('names')"
            size="small"
            type="border"
            color="success"
            :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
            :disabled="readOnly"
            >Add</vs-button
          >
        </div>
      </div>
      <!--#endregion Name fields field ends -->

      <!--#region Room Names  field  -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full text-sm">
          <span>Room Names:</span>
        </div>
        <template
          v-for="(roomName, roomNameIndex) in acomLocalForm.accommodationRuleCriteria
            .roomNames"
        >
          <!-- Origin -->
          <div class="vx-col w-full md:w-1/3" :key="roomNameIndex + '0'">
            <div class="mt-4">
              <label class="text-sm">MatchType</label>
              <v-select
                class="w-full"
                :name="'roomNameMatch_' + roomNameIndex"
                v-model="
                  acomLocalForm.accommodationRuleCriteria.roomNames[roomNameIndex]
                    .matchType
                "
                :options="acomLocalHelper.enums.matchTypes"
                :reduce="(option) => option.code"
                v-validate="{ rules: { required: true} }"
                :disabled="readOnly"
              />
              <span
                class="text-danger text-sm"
                v-if="errors.has('roomNameMatch_' + roomNameIndex)"
                >{{
                  errors
                    .first("roomNameMatch_" + roomNameIndex)
                    .replace("roomNameMatch_" + roomNameIndex, "Room Name match type")
                }}</span
              >
            </div>
          </div>
          <!-- Value -->
          <div class="vx-col w-full md:w-1/3" :key="roomNameIndex + '1'">
            <div class="mt-4">
              <label class="text-sm">Value</label>
              <vs-input
                class="w-full"
                :name="'roomNameValue_' + roomNameIndex"
                v-model="
                  acomLocalForm.accommodationRuleCriteria.roomNames[roomNameIndex].value
                "
                v-validate="{ rules: { required: true } }"
                :disabled="readOnly"
              />
              <span class="text-danger text-sm" v-if="errors.has('roomNameValue_' + roomNameIndex)">{{ errors.first('roomNameValue_'+roomNameIndex).replace('roomNameValue_'+roomNameIndex, "Room Name value") }}</span>
            </div>
          </div>
          <div class="vx-col w-full md:w-1/3" :key="roomNameIndex + '2'">
            <div class="mt-3">
              <!-- <label class="text-sm">Max</label> -->
              <vs-button
                @click="removeAcomDynamicFields(roomNameIndex, 'roomNames')"
                size="small"
                class="mt-8"
                type="border"
                color="danger"
                :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
                :disabled="readOnly"
                >Remove</vs-button
              >
            </div>
          </div>
        </template>

        <div class="vx-col w-full my-3">
          <vs-button
            @click="addAcomDynamicFields('roomNames')"
            size="small"
            type="border"
            color="success"
            :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
            :disabled="readOnly"
            >Add</vs-button
          >
        </div>
      </div>     
      

      <!--#endregion Room Name fields field ends -->

      <!--#region Chains  field  -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full text-sm">
          <span>Chains:</span>
        </div>
        <template
          v-for="(chain, chainIndex) in acomLocalForm.accommodationRuleCriteria
            .chains"
        >
          <!-- Origin -->
          <div class="vx-col w-full md:w-1/3" :key="chainIndex + '0'">
            <div class="mt-4">
              <label class="text-sm">MatchType</label>
              <v-select
                class="w-full"
                :name="'chainMatch_' + chainIndex"
                v-model="
                  acomLocalForm.accommodationRuleCriteria.chains[chainIndex]
                    .matchType
                "
                :options="acomLocalHelper.enums.matchTypes"
                :reduce="(option) => option.code"
                v-validate="{ rules: { required: true} }"
                :disabled="readOnly"
              />
              <span
                class="text-danger text-sm"
                v-if="errors.has('chainMatch_' + chainIndex)"
                >{{
                  errors
                    .first("chainMatch_" + chainIndex)
                    .replace("chainMatch_" + chainIndex, "Chain match type")
                }}</span
              >
            </div>
          </div>
          <!-- Value -->
          <div class="vx-col w-full md:w-1/3" :key="chainIndex + '1'">
            <div class="mt-4">
              <label class="text-sm">Value</label>
              <vs-input
                class="w-full"
                :name="'chainValue_' + chainIndex"
                v-model="
                  acomLocalForm.accommodationRuleCriteria.chains[chainIndex]
                    .value
                "
                v-validate="{ rules: { required: true } }"
                :disabled="readOnly"
              />
              <span class="text-danger text-sm" v-if="errors.has('chainValue_' + chainIndex)">{{ errors.first('chainValue_'+chainIndex).replace('chainValue_'+chainIndex, "Chain value") }}</span>
            </div>
          </div>
          <div class="vx-col w-full md:w-1/3" :key="chainIndex + '2'">
            <div class="mt-3">
              <!-- <label class="text-sm">Max</label> -->
              <vs-button
                @click="removeAcomDynamicFields(chainIndex, 'chains')"
                size="small"
                class="mt-8"
                type="border"
                color="danger"
                :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
                :disabled="readOnly"
                >Remove</vs-button
              >
            </div>
          </div>
        </template>

        <div class="vx-col w-full my-3">
          <vs-button
            @click="addAcomDynamicFields('chains')"
            size="small"
            type="border"
            color="success"
            :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
            :disabled="readOnly"
            >Add</vs-button
          >
        </div>
      </div>
      <!--#endregion Chain fields field ends -->

      <!--#region Locations  field  -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full text-sm">
          <span>Locations:</span>
        </div>
        <template
          v-for="(location, locationIndex) in acomLocalForm
            .accommodationRuleCriteria.locations"
        >
          <!-- Origin -->
          <div class="vx-col w-full md:w-1/3" :key="locationIndex + '0'">
            <div class="mt-4">
              <label class="text-sm">MatchType</label>
              <v-select
                class="w-full"
                :name="'locationMatch_' + locationIndex"
                v-model="
                  acomLocalForm.accommodationRuleCriteria.locations[
                    locationIndex
                  ].matchType
                "
                :options="acomLocalHelper.enums.matchTypes"
                :reduce="(option) => option.code"
                v-validate="{ rules: { required: true} }"
                :disabled="readOnly"
              />
               <span
                class="text-danger text-sm"
                v-if="errors.has('locationMatch_' + locationIndex)"
                >{{
                  errors
                    .first("locationMatch_" + locationIndex)
                    .replace("locationMatch_" + locationIndex, "Location match type")
                }}</span
              >
            </div>
          </div>
          <!-- Value -->
          <div class="vx-col w-full md:w-1/3" :key="locationIndex + '1'">
            <div class="mt-4">
              <label class="text-sm">Value</label>
              <vs-input
                class="w-full"
                :name="'locationValue_' + locationIndex"
                v-model="
                  acomLocalForm.accommodationRuleCriteria.locations[
                    locationIndex
                  ].value
                "
                v-validate="{ rules: { required: true, max: 25 } }"
                :disabled="readOnly"
              />
              <span
                class="text-danger text-sm"
                v-if="errors.has('locationValue_' + locationIndex)"
                >{{
                  errors
                    .first("locationValue_" + locationIndex)
                    .replace("locationValue_" + locationIndex, "Location value")
                }}</span
              >
            </div>
          </div>
          <div class="vx-col w-full md:w-1/3" :key="locationIndex + '2'">
            <div class="mt-3">
              <!-- <label class="text-sm">Max</label> -->
              <vs-button
                @click="removeAcomDynamicFields(locationIndex, 'location')"
                size="small"
                class="mt-8"
                type="border"
                color="danger"
                :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
                :disabled="readOnly"
                >Remove</vs-button
              >
            </div>
          </div>
        </template>

        <div class="vx-col w-full my-3">
          <vs-button
            @click="addAcomDynamicFields('location')"
            size="small"
            type="border"
            color="success"
            :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
            :disabled="readOnly"
            >Add</vs-button
          >
        </div>
      </div>
      <!--#endregion Location fields  ends -->
    </vx-card>
  </div>
</template>

<script>
import _ from "lodash";
import { CustomValidator } from "../PricingRuleEditorValidator.js";
import StarRating from "vue-star-rating";
const customvalidator = new CustomValidator();
customvalidator.setupErrorMessages();

export default {
  name: "AccommodationRuleCriteria",
  components: { StarRating },
  inject: ["$validator"],
  props: {
    helper: {
      type: Object,
    },
    form: {
      type: Object,
    },
    priceRulesFormData: {
      type: Object,
    },
    readOnly: {
      type: Boolean,
    },
  },
  data() {
    return {
      acomLocalHelper: this.helper,
      acomLocalForm: this.form,
    };
  },

  methods: {
    validateform() {
      return this.$validator.validateAll();
    },
    //#region  Adding dynamic route fields in the form
    addAcomDynamicFields(fieldType) {
      if (fieldType === "names") {
        this.acomLocalForm.accommodationRuleCriteria.names.push(
          _.cloneDeep(this.acomLocalHelper.stringMatch)
        );
      }
      if (fieldType === "roomNames") {
        if(!this.acomLocalForm.accommodationRuleCriteria.roomNames) {
          this.acomLocalForm.accommodationRuleCriteria.roomNames = []
          this.acomLocalForm.accommodationRuleCriteria = _.cloneDeep(
            this.acomLocalHelper.accommodationCriteria
          );
        }
        this.acomLocalForm.accommodationRuleCriteria.roomNames.push(
          _.cloneDeep(this.acomLocalHelper.stringMatch)
        );
      }
      if (fieldType === "brands") {
        this.acomLocalForm.accommodationRuleCriteria.brands.push(
          _.cloneDeep(this.acomLocalHelper.stringMatch)
        );
      }
      if (fieldType === "chains") {
        this.acomLocalForm.accommodationRuleCriteria.chains.push(
          _.cloneDeep(this.acomLocalHelper.stringMatch)
        );
      }
      if (fieldType === "ratings") {
        this.acomLocalForm.accommodationRuleCriteria.ratings.push(0);
      }
      if (fieldType === "location") {
        this.acomLocalForm.accommodationRuleCriteria.locations.push(
          _.cloneDeep(this.acomLocalHelper.stringMatch)
        );
      }
    },
    //#endregion

    //#region  Removing dynamic route fields in the form
    removeAcomDynamicFields(index, fieldType) {
      if (fieldType === "names") {
        this.acomLocalForm.accommodationRuleCriteria.names.splice(index, 1);
      }
      if (fieldType === "roomNames") {
        this.acomLocalForm.accommodationRuleCriteria.roomNames.splice(index, 1);
      }
      if (fieldType === "brands") {
        this.acomLocalForm.accommodationRuleCriteria.brands.splice(index, 1);
      }
      if (fieldType === "chains") {
        this.acomLocalForm.accommodationRuleCriteria.chains.splice(index, 1);
      }
      if (fieldType === "ratings") {
        this.acomLocalForm.accommodationRuleCriteria.ratings.splice(index, 1);
      }
      if (fieldType === "location") {
        this.acomLocalForm.accommodationRuleCriteria.locations.splice(index, 1);
      }
    },
    //#endregion
  },
  /**
   * When check box is unchecked, all its related form  should be reset
   */
  watch: {
    "form.productTypes": {
      deep: true,
      immediate: true,
      handler: function (newValue, oldValue) {
        if (
          oldValue &&
          oldValue.length > 0 &&
          oldValue.includes("Accommodation") &&
          newValue &&
          !newValue.includes("Accommodation")
        ) {
          this.acomLocalForm.accommodationRuleCriteria = _.cloneDeep(
            this.acomLocalHelper.accommodationCriteria
          );
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
</style>