<template>
  <div>
    <vx-card class="" no-shadow card-border>
      <div class="vx-row mb-6">
        <div class="vx-col w-full align-center my-2">
          <span>Tickets & Attractions Rule Criteria:</span>
        </div>
        <!--#region  suppliers field-->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-col w-full text-sm">
            <span>Suppliers:</span>
          </div>
          <div class="mt-4">
            <v-select
              multiple
              name="ticketSuppliers"
              v-model="ticketExtraLocalForm.ticketsRuleCriteria.suppliers"
              :reduce="(x) => x.code"
              :disabled="readOnly"
              :options="priceRulesFormData.ticketSupplier"
              v-validate="{ rules: { required: false } }"
            />
            <!-- <span class="text-danger text-sm" v-if="errors.has('ticketSuppliers')">{{ errors.first('ticketSuppliers') }}</span> -->
          </div>
        </div>
        <!--#endregion  suppliers field-->

        <!--#region  Ticket Types field-->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-col w-full text-sm">
            <span>Ticket Types:</span>
          </div>
          <div class="mt-4">
            <v-select
              multiple
              name="ticketTypes"
              v-model="ticketExtraLocalForm.ticketsRuleCriteria.ticketTypes"
              :reduce="(x) => x.code"
              :disabled="readOnly"
              :options="ticketLocalHelper.enums.ticketTypes"
              v-validate="{ rules: { required: false } }"
            />
            <!-- <span class="text-danger text-sm" v-if="errors.has('ticketTypes')">{{ errors.first('ticketTypes') }}</span> -->
          </div>
        </div>
        <!--#endregion  Ticket Types ends-->
      </div>
      <div class="vx-row mb-6">
        <!--#region Country field -->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-col w-full text-sm">
            <span>Country:</span>
          </div>
          <div class="mt-4">
            <v-select
              multiple
              v-model="ticketExtraLocalForm.ticketsRuleCriteria.countries"
              :reduce="(x) => x.code"
              :disabled="readOnly"
              :options="priceRulesFormData.countryCode"
            />
            <span class="text-danger text-sm">{{
              errors.first("countries")
            }}</span>
          </div>
        </div>
        <!--#endregion Country field ends -->
      </div>

      <!--#region Locations  field  -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full text-sm">
          <span>Locations:</span>
        </div>
        <template
          v-for="(ticklocation, ticklocationIndex) in ticketExtraLocalForm
            .ticketsRuleCriteria.locations"
        >
          <!-- Origin -->
          <div class="vx-col w-full md:w-1/3" :key="ticklocationIndex + '0'">
            <div class="mt-4">
              <label class="text-sm">MatchType</label>
              <v-select
                class="w-full"
                :name="'tickLocationMatch_' + ticklocationIndex"
                v-model="
                  ticketExtraLocalForm.ticketsRuleCriteria.locations[
                    ticklocationIndex
                  ].matchType
                "
                :options="ticketLocalHelper.enums.matchTypes"
                :reduce="(option) => option.code"
                v-validate="{ rules: { required: true} }"
                :disabled="readOnly"
              />
              <span
                class="text-danger text-sm"
                v-if="errors.has('tickLocationMatch_' + ticklocationIndex)"
                >{{
                  errors
                    .first("tickLocationMatch_" + ticklocationIndex)
                    .replace("tickLocationMatch_" + ticklocationIndex, "Location match type")
                }}</span
              >
            </div>
          </div>
          <!-- Value -->
          <div class="vx-col w-full md:w-1/3" :key="ticklocationIndex + '1'">
            <div class="mt-4">
              <label class="text-sm">Value</label>
              <vs-input
                class="w-full"
                :name="'tickLocationValue_' + ticklocationIndex"
                v-model="
                  ticketExtraLocalForm.ticketsRuleCriteria.locations[
                    ticklocationIndex
                  ].value
                "
                v-validate="{ rules: { required: true, max: 25 } }"
                :disabled="readOnly"
              />
              <span
                class="text-danger text-sm"
                v-if="errors.has('tickLocationValue_' + ticklocationIndex)"
                >{{
                  errors
                    .first("tickLocationValue_" + ticklocationIndex)
                    .replace("tickLocationValue_" + ticklocationIndex, "value")
                }}</span
              >
            </div>
          </div>
          <div class="vx-col w-full md:w-1/3" :key="ticklocationIndex + '2'">
            <div class="mt-3">
              <!-- <label class="text-sm">Max</label> -->
              <vs-button
                @click="
                  removetickDynamicFields(ticklocationIndex, 'ticklocation')
                "
                size="small"
                class="mt-8"
                type="border"
                color="danger"
                :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
                :disabled="readOnly"
                >Remove</vs-button
              >
            </div>
          </div>
        </template>

        <div class="vx-col w-full my-3">
          <vs-button
            @click="addtickDynamicFields('ticklocation')"
            size="small"
            type="border"
            color="success"
            :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
            :disabled="readOnly"
            >Add</vs-button
          >
        </div>
      </div>
      <!--#endregion Location fields  ends -->
    </vx-card>
  </div>
</template>

<script>
import _ from "lodash";
import { CustomValidator } from "../PricingRuleEditorValidator.js";
const customvalidator = new CustomValidator();
customvalidator.setupErrorMessages();

export default {
  name: "TicketsCriteria",
  components: {},
  inject: ["$validator"],
  props: {
    helper: {
      type: Object,
    },
    form: {
      type: Object,
    },
    priceRulesFormData: {
      type: Object,
    },
    readOnly: {
      type: Boolean,
    },
  },
  data() {
    return {
      ticketLocalHelper: this.helper,
      ticketExtraLocalForm: this.form,
    };
  },

  methods: {
    validateform() {
      return this.$validator.validateAll();
    },
    //#region  Adding dynamic route fields in the form
    addtickDynamicFields(fieldType) {
      if (fieldType === "ticklocation") {
        this.ticketExtraLocalForm.ticketsRuleCriteria.locations.push(
          _.cloneDeep(this.ticketLocalHelper.stringMatch)
        );
      }
    },
    //#endregion

    //#region  Removing dynamic route fields in the form
    removetickDynamicFields(index, fieldType) {
      if (fieldType === "ticklocation") {
        this.ticketExtraLocalForm.ticketsRuleCriteria.locations.splice(
          index,
          1
        );
      }
    },
    //#endregion
  },

  /**
   * When check box is unchecked, all its related form  should be reset   *
   */
  watch: {
    "form.productTypes": {
      deep: true,
      immediate: true,
      handler: function (newValue, oldValue) {
        if (
          oldValue &&
          oldValue.length > 0 &&
          oldValue.includes("TicketsAttractions") &&
          newValue &&
          !newValue.includes("TicketsAttractions")
        ) {
          this.ticketExtraLocalForm.ticketsRuleCriteria = _.cloneDeep(
            this.ticketLocalHelper.ticketsCriteria
          );
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
</style>