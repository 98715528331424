import { Validator } from 'vee-validate';

const dict = {
  custom: {
    name: {
      required: 'Pricing rule name is required',
      max: "Max length is 100 characters"
    },
    flightDestination:{
      required: 'Pricing rule name is required',
      max: "Max length is 100 characters"
    }, 
    modifierAppliesTo:{
      required: 'Modifier applies to is required',
      max: "Max length is 100 characters"
    },    
    priority: {
      max_value: "Max priority value is 100",
      min_value: "Min priority value is 1"
    },
    modifierValue: {
      min_value: "Only positive value is allowed"
    },
  }
};

export class CustomValidator {
  constructor() {}
  setupErrorMessages ()  {
    Validator.localize('en', dict);
  }
}
